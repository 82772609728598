import { useEffect } from 'react';
// import { navigate } from 'gatsby';
export const newUrl = 'https://coachportal.weareheadlight.com';

export default function Index() {
  function redirect() {
    // navigate('/participants/', { replace: true });
    window.location.replace(newUrl);
  }
  useEffect(redirect);
  return null;
}
